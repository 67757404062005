<template>
  <v-data-table
    :headers="headers"
    :items="tableData"
    sort-by="calories"
    class="elevation-1"
  >
    <template #item.receptionDate="{item}">
      <span>
        {{ item.receptionDate | dateFormatter}}
      </span>
    </template>
    <template #item.warehouseId="{item}">
      <span>
        {{
          getNameById(item.warehouseId, 'warehouses', 'name')
        }}
      </span>
    </template>
    <template #item.supplierId="{item}">
      <span>
        {{
          getNameById(item.supplierId, 'suppliers', 'name')
        }}
      </span>
    </template>
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>{{config.title.main}}</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-dialog
          v-model="dialog"
          max-width="1000px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="config.permissions.create"
              color="#9DC033"
              dark
              v-bind="attrs"
              v-on="on"
              @click="dialogTitle = config.title.create"
            >
              Добавить
              <v-icon>
                mdi-plus
              </v-icon>
            </v-btn>
          </template>
          <v-card
            color="#F7F7F6"
          >
            <v-card-title class="headline"/>

            <v-card-text>
              <div class="row--dense" style="border: 1px solid;">
                <div class="d-flex justify-space-between align-center"
                     style="border-bottom: 1px solid; height: 55px; max-height: 55px"
                >
                  <div class="d-flex col-4 justify-start">
                    <h1 class="pr-10">{{ dialogTitle }}</h1>
                    <div v-if="editId">#{{item.id}}</div>
                  </div>
                  <div v-if="editId" class="d-flex col-8 justify-end">
                    <div class="d-flex flex-column mr-5" >
                      <span>Автор: {{ userCreated ? userCreated : '' }} </span>
                      <span>Дата и время:
                        {{ (item && item.createdDate) ? dateFormatter(item.createdDate) : ''}}
                      </span>
                    </div>
                    <div class="d-flex flex-column">
                      <span>Редактор: {{ userModified ? userModified : ''}} </span>
                      <span>Дата и время:
                  {{ (item && item.lastModifiedDate) ? dateFormatter(item.lastModifiedDate) : ''}}
                      </span>
                    </div>
                  </div>
                </div>
                <div>
                  <v-form ref="form"
                          v-model="valid"
                          lazy-validation
                  >
                    <div>
                      <div class="pa-5">
                        <div class="item"
                             v-for="col in columns" :key="col.value">
                          <span class="col-xs-12 col-sm-5">
                            {{ col.text }}
                          </span>
                          <component :is="col.component" class="col-xs-12 col-sm-7"
                                     :column-name="col.value" @submit="onSubmit"
                                     :store="col.store"
                                     :rules="col.rules"
                          />
                        </div>
                      </div>
                    </div>
                  </v-form>
                </div>
              </div>
            </v-card-text>

            <v-card-actions class="pb-5">
              <v-spacer></v-spacer>
              <v-btn
                color="warning"
                dark
                text
                @click="close"
              >
                Отменить
              </v-btn>
              <v-btn
                color="#9DC033"
                @click="save"
                dark
              >
                Сохранить
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
    </template>
  </v-data-table>

</template>

<script>

import axios from 'axios';
import * as components from '@/components/cm-table/form';
import moment from 'moment';

export default {
  name: 'index',
  components: {
    ...components,
  },
  filters: {
    dateFormatter(val) {
      if (moment(val).isValid()) {
        return moment(val, 'YYYY-MM-DDTHH:mm:ss.sss[Z]').format('DD-MM-YYYY HH:mm');
      }
      return '';
    },
  },
  data() {
    return {
      headers: [
        { value: 'organization', sortable: false, text: 'Организация' },
        { value: 'receptionDate', sortable: false, text: 'Дата приема' },
        { value: 'status', sortable: false, text: 'Статус' },
        { value: 'warehouseId', sortable: false, text: 'Склад' },
        { value: 'supplierId', sortable: false, text: 'Поставщик' },
        { value: 'comment', sortable: false, text: 'Комментарий' },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
        },
      ],
      config: {
        title: {
          main: 'Заявки',
          create: 'Создать',
          edit: 'Редактировать',
          delete: 'Удалить',
        },
        permissions: {
          create: true,
          edit: true,
          delete: true,
        },
        endpoints: {
          get: {
            method: 'get',
            url: '/api/v1/crm/supplier/requests',
            content: true,
          },
          create: {
            method: 'post',
            url: '/api/v1/crm/supplier/requests',
          },
          edit: {
            method: 'put',
            url: '/api/v1/crm/supplier/requests',
          },
        },
      },
      columns: [
        { value: 'organization', text: 'Организация', component: 'cmTextField' },
        { value: 'receptionDate', text: 'Дата приема', component: 'cmDatePicker' },
        {
          value: 'status', text: 'Статус', component: 'cmList', store: 'requestStatus',
        },
        {
          value: 'warehouseId',
          text: 'Склад',
          component: 'cmList',
          store: 'warehouses',
          rules: [
            (v) => !!v || 'Name is required',
          ],
        },
        {
          value: 'supplierId', text: 'Поставщик', component: 'cmList', store: 'suppliers',
        },
        { value: 'comment', text: 'Комментарий', component: 'cmTextField' },
      ],
      loaded: false,
      dialogTitle: null,
      tableData: [],
      valid: true,
      userCreated: null,
      userModified: null,
      item: {},
      editId: null,
      dialog: false,
      dialogDelete: false,
    };
  },
  created() {
    this.initialize();
  },
  watch: {
    editId: {
      handler(val) {
        if (val) {
          this.item = { ...this.tableData.find((i) => i.id === val) };
          this.setUserInformation();
        }
      },
    },
  },
  methods: {
    initialize() {
      const routes = [
        { path: '/api/v1/crm/suppliers', state: 'suppliers' },
        { path: '/api/v1/wms/warehouses', state: 'warehouses' },
      ];
      const promises = [];
      routes.forEach((i) => {
        const promise = this.$api.get(i.path).then((response) => {
          this.$store.dispatch('entity/actionSetEntity', {
            state: i.state,
            data: response.content ? response.content : response,
          });
        });
        promises.push(promise);
      });
      axios.all(promises).then(() => {
        this.$api[this.config.endpoints.get.method](this.config.endpoints.get.url)
          .then((response) => {
            if (this.config.endpoints.get.content) {
              this.tableData = [...response.content];
            } else {
              this.tableData = [...response];
            }
            this.loaded = true;
          });
      });
    },
    editItem(item) {
      this.dialogTitle = this.config.title.edit;
      this.editId = item.id;
      this.dialog = true;
    },

    deleteItem(item) {
      this.dialogTitle = this.config.title.delete;
      this.editId = item.id;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.$api[this.config.endpoints.delete.method](this.config.endpoints.delete.url + this.editId)
        .then(() => {
          this.initialize();
        }).finally(() => {
          this.closeDelete();
        });
    },

    close() {
      this.dialog = false;
      this.reset();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.reset();
    },

    save() {
      this.$loading(true);
      if (this.validate()) {
        const { method, url } = this.config.endpoints[this.editId ? 'edit' : 'create'];
        this.$api[method](url, { ...this.item }).then(() => {
          this.reset();
          this.close();
        }).catch((e) => {
          this.$log.warn(e);
        }).finally(() => {
          this.$loading(false);
        });
      }
    },
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.editId = null;
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },

    dateFormatter(val) {
      return moment(val).format('DD-MM-YYYY HH:mm');
    },
    getUserInfo(id) {
      return this.$api.get(`/api/v1/crm/users/${id}`);
    },
    setUserInformation() {
      this.getUserInfo(this.item.createdBy).then((response) => {
        this.$nextTick(() => {
          this.userCreated = `${response.firstName} ${response.lastName}`;
        });
      });
      this.getUserInfo(this.item.lastModifiedBy).then((response) => {
        this.$nextTick(() => {
          this.userModified = `${response.firstName} ${response.lastName}`;
        });
      });
    },
    onSubmit({ key, value }) {
      this.item[key] = value;
    },
    getNameById(id, store, key) {
      return this.$store.getters['entity/getEntity'](store).find((i) => i.id === id)[key];
    },
  },
};
</script>

<style scoped>

span {
  font-family: Open Sans,sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #7E8271;
}

.item {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
}
</style>
